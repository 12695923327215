import {request} from "../../util/Request"
import qs from "qs"

export default {
    data() {
        return {
            exportModal: {
                id: null,
                visibility: false,
                columns: [
                    {id: 'order_id', label: this.$t('title.orderId'), show: true},
                    {id: 'load_status', label: this.$t('title.status'), show: true},
                    {id: 'vin', label: this.$t('title.vinNumber'), show: true},
                    {id: 'from_location', label: this.$t('title.fromLocation'), show: true},
                    {id: 'to_location', label: this.$t('title.toLocation'), show: true},
                    {id: 'client', label: this.$t('title.client'), show: true},
                    {id: 'manufacturer', label: this.$t('title.manufacturer'), show: true},
                    {id: 'brand', label: this.$t('title.brand'), show: true},
                    {id: 'model', label: this.$t('title.model'), show: true},
                    {id: 'invoice_number_importer', label: this.$t('title.invoiceNumberImporter'), show: true},
                    {id: 'supplier_carrier_id', label: this.$t('title.supplierCarrier'), show: true},
                    {id: 'supplier_compound_id', label: this.$t('title.supplierCompound'), show: true},
                    {id: 'client_tr_at', label: this.$t('title.clientTransportRequest'), show: true},
                    {id: 'client_tr_accept_at', label: this.$t('title.clientTransportRequestAcceptAt'), show: true},
                    {id: 'comments', label: this.$t('title.comments'), show: true},
                    {id: 'market', label: this.$t('title.market'), show: true},
                    {id: 'date_of_availability', label: this.$t('title.dateOfAvailability'), show: true},
                ],
                headers: {
                    [this.$t('column.orderId')]: 'order_id',
                    [this.$t('column.status')]: 'load_status',
                    [this.$t('column.vin')]: 'vin',
                    [this.$t('column.fromLocation')]: 'from_location',
                    [this.$t('column.toLocation')]: 'to_location',
                    [this.$t('column.client')]: 'client',
                    [this.$t('column.manufacturer')]: 'manufacturer',
                    [this.$t('column.brand')]: 'brand',
                    [this.$t('column.model')]: 'model',
                    [this.$t('column.invoiceNumberImporter')]: 'invoice_number_importer',
                    [this.$t('column.supplierCarrier')]: 'supplier_carrier_id',
                    [this.$t('column.supplierCompound')]: 'supplier_compound_id',
                    [this.$t('column.clientTransportRequest')]: 'client_tr_at',
                    [this.$t('column.clientTransportRequestAcceptAt')]: 'client_tr_accept_at',
                    [this.$t('column.market')]: 'market',
                    [this.$t('column.dateOfAvailability')]: 'date_of_availability',
                }
            },
        }
    },
    methods: {
        handleExcelExportClick(id) {
            this.exportModal.id = id
            this.exportModal.visibility = true
        },
        async handleExcelExportSubmitClick() {
            let columns = _.filter(this.exportModal.columns, (item) => item.show).map(item => item.id)
            const headers = {}
            _.map(this.exportModal.headers, (index, item) => {
                if(index && _.includes(columns, index)) {
                    headers[item] = index
                }
            })
            this.exportModal.headers = headers

            const extraParams = this.getExtraParams() // from listingMixin
            let newListQueryParams = this.listQueryParams // from listingMixin
            const params = {...newListQueryParams, ...extraParams}
            const response = await request({
                method: "get",
                url: 'orders/excel',
                params: params,
                paramsSerializer: ((params) => qs.stringify(params)),
            })

            const {data} = response
            const rows = _.map(data, (item, index) => _.pick({
                order_id:  this.$global.val(item.order_id),
                load_status: this.$global.val(item._load_status),
                vin: this.$global.val(item.vin_number),
                from_location: this.$global.val((item.from_location || {}).label),
                to_location: this.$global.val((item.to_location || {}).label),
                client: this.$global.val((item.client || {}).company_name),
                manufacturer: this.$global.val((item.manufacturer || {}).name),
                brand: this.$global.val(item.brand.title),
                model: this.$global.val(item.model.title),
                invoice_number_importer: (this.$global.val(item.invoice_number_importer)? "_"+this.$global.val(item.invoice_number_importer) : ""),
                supplier_carrier_id: this.$global.val((item.supplier_carrier || {}).name),
                supplier_compound_id: this.$global.val((item.supplier_compound || {}).name),
                client_tr_at: this.$global.val(item.client_tr_at),
                client_tr_accept_at: this.$global.val(item.client_tr_accept_at),
                comments: this.$global.val(item.comments),
                market: this.$global.val(item.order_country),
                date_of_availability: (this.$global.val(item.manufacturer_invoice_date) ? this.$global.val(this.$global.dateFormat(item.manufacturer_invoice_date)) : ''),
            }, columns))
            return rows;
        },
        async handleSingleExcelExportSubmitClick() {
            let columns = _.filter(this.exportModal.columns, (item) => item.show).map(item => item.id)
            const headers = {}
            _.map(this.exportModal.headers, (index, item) => {
                if(index && _.includes(columns, index)) {
                    headers[item] = index
                }
            })
            this.exportModal.headers = headers

            const response = await request({
                method: "get",
                url: `orders/excel/detail/${this.exportModal.id}`,
            })

            const {data} = response
            const rows = [];
            const row = _.pick({
                order_id:  this.$global.val(data.order_id),
                load_status: this.$global.val(data._load_status),
                vin: this.$global.val(data.vin_number),
                from_location: this.$global.val((data.from_location || {}).label),
                to_location: this.$global.val((data.to_location || {}).label),
                client: this.$global.val((data.client || {}).company_name),
                manufacturer: this.$global.val((data.manufacturer || {}).name),
                brand: this.$global.val(data.brand.title),
                model: this.$global.val(data.model.title),
                invoice_number_importer: this.$global.val(data.invoice_number_importer),
                call_off_date_importer: this.$global.val(data.call_off_date_importer),
                supplier_carrier_id: this.$global.val((data.supplier_carrier || {}).name),
                supplier_compound_id: this.$global.val((data.supplier_compound || {}).name),
                client_tr_at: this.$global.val(data.client_tr_at),
                client_tr_accept_at: this.$global.val(data.client_tr_accept_at),
                comments: this.$global.val(data.comments),
                market: this.$global.val(data.order_country),
                date_of_availability: this.$global.val(data.manufacturer_invoice_date),
            }, columns)
            rows.push(row)
            return rows;
        },
        handleExcelExportCancelClick() {
            this.exportModal.visibility = false
            this.exportModal.id = null
        },
        handleExcelExportSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = true
            })
        },
        handleExcelExportUnSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = false
            })
        },
    }
}
